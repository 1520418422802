<script setup>
import { computed, onMounted, ref, watchEffect } from "vue";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { usePage } from "@inertiajs/vue3";
import eventBus from "@/Helpers/eventBus";

const show = ref(true);
let toastTimeoutId = null;
const message = ref(null);
const myMessage = ref(null);

// Jetstream uses bannerStyle internally
const style = computed(
    () =>
        usePage().props.jetstream.flash?.toastStyle ||
        usePage().props.jetstream.flash?.bannerStyle ||
        "success",
);

onMounted(() => {
    eventBus.$on("showToast", (value) => {
        myMessage.value = value.message;
        show.value = true;
    });
});

watchEffect(async () => {
    message.value = "";
    // jo uz 404 neeksistē jetstream
    if (usePage().props.jetstream) {
        message.value =
            usePage().props.jetstream.flash?.toast ||
            usePage().props.jetstream.flash?.banner ||
            myMessage.value;
    }

    show.value = true;
    if (toastTimeoutId) {
        clearTimeout(toastTimeoutId);
    }
    toastTimeoutId = setTimeout(() => {
        show.value = false;
        myMessage.value = null;
    }, 10000);
});
</script>

<template>
    <div
        v-if="message"
        aria-live="assertive"
        class="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
        <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <transition
                enter-active-class="transform ease-out duration-300 transition"
                enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div
                    v-if="show"
                    class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                    :class="{
                        'rounded-lg border border-red-300 bg-red-50 text-red-800': style === 'danger',
                        'border-green-300 bg-green-50 text-green-800': style === 'success',
                        'rounded-lg border border-yellow-300 bg-yellow-50 text-yellow-800': style === 'warning',
                    }"
                >
                    <div class="p-4">
                        <div class="flex items-start">
                            <div class="shrink-0">
                                <ExclamationCircleIcon
                                    v-if="style === 'danger'"
                                    class="size-6 text-red-400"
                                />
                                <CheckCircleIcon
                                    v-if="style === 'success'"
                                    class="size-6 text-green-400"
                                    aria-hidden="true"
                                />
                                <CheckCircleIcon
                                    v-if="style === 'warning'"
                                    class="size-6 text-orange-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="text-sm font-medium">
                                    {{ style === "success" ? "Success" : "" }}
                                    {{ style === "warning" ? "Warning" : "" }}
                                    {{ style === "danger" ? "Error" : "" }}
                                </p>
                                <p class="mt-1 text-sm">
                                    {{ message }}
                                </p>
                            </div>
                            <div class="ml-4 flex shrink-0">
                                <button
                                    type="button"
                                    class="inline-flex rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    @click="show = false"
                                >
                                    <span class="sr-only">Close</span>
                                    <XMarkIcon
                                        class="size-5"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
